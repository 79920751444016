export default {
  get(key: string) {
    const value = window.localStorage.getItem(key)
    return value ? JSON.parse(value) : undefined
  },
  set(key: string, value: any) {
    window.localStorage.setItem(key, value ? JSON.stringify(value) : '')
  },
  remove(key: string) {
    window.localStorage.removeItem(key)
  },
  clear() {
    window.localStorage.clear()
  },
  session: {
    get(key: string) {
      const value = window.sessionStorage.getItem(key)
      return value ? JSON.parse(value) : undefined
    },
    set(key: string, value: any) {
      window.sessionStorage.setItem(key, value ? JSON.stringify(value) : '')
    },
    remove(key: string) {
      window.sessionStorage.removeItem(key)
    },
    clear() {
      window.sessionStorage.clear()
    }
  }
}
