import { createApp } from 'vue'
import Provider from './components/Provider.vue'
import router from './router'
import store from './store'
import NaiveUI from './plugins/naive-ui'
import 'tailwindcss/tailwind.css'
import Vue3Lottie from 'vue3-lottie'
import 'vue3-lottie/dist/style.css'
import './assets/styles/index.css'

const app = createApp(Provider)

app.use(store)
app.use(router)
app.use(NaiveUI)
app.use(Vue3Lottie)

// 防止naive-ui样式被tailwind.css覆盖了
const meta = document.createElement('meta')
meta.name = 'naive-ui-style'
document.head.appendChild(meta)

app.mount('#app')
