import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import storage from '@/utils/storage'
import Home from 'views/home/index.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/login',
    name: 'Login',
    meta: {
      unAuth: true,
      loginPage: true,
      keyName: 'Login'
    },
    component: () => import('@/views/login/index.vue')
  },
  {
    path: '/news',
    name: 'News',
    component: () => import('@/views/news/index.vue'),
    redirect: {
      name: 'NewsList'
    },
    meta: {
      keyName: 'News'
    },
    children: [
      {
        path: 'list',
        component: () => import('@/views/news/list/index.vue'),
        name: 'NewsList',
        meta: {
          keyName: 'News'
        }
      },
      {
        path: 'create',
        component: () => import('@/views/news/create/index.vue'),
        name: 'NewsCreate',
        meta: {
          keyName: 'News'
        }
      },
      {
        path: 'update/:id',
        props: true,
        component: () => import('@/views/news/create/index.vue'),
        name: 'NewsUpdate',
        meta: {
          keyName: 'News'
        }
      }
    ]
  },
  {
    path: '/admin',
    name: 'Admin',
    component: () => import('@/views/admin/index.vue')
  },
  {
    path: '/product',
    name: 'Product',
    component: () => import('@/views/product/index.vue')
  },
  {
    path: '/setting',
    name: 'Setting',
    component: () => import('@/views/setting/index.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach((to) => {
  const token = storage.session.get('token')

  if (to.meta.unAuth) {
    if (to.name === 'Login' && token) {
      return {
        name: 'Home',
        replace: true
      }
    }
    return true
  } else {
    if (!token) {
      return {
        name: 'Login',
        replace: true
      }
    } else {
      return true
    }
  }
})

export default router
