import type { App } from 'vue'

import {
  create,
  NButton,
  NForm,
  NFormItem,
  NInput,
  NCard,
  NIcon,
  NNotificationProvider,
  NConfigProvider,
  NDialogProvider,
  NMessageProvider,
  NMenu,
  NDatePicker,
  NDataTable,
  NPagination,
  NSpin,
  NSwitch,
  NLayoutSider,
  NLayout,
  NLayoutHeader,
  NLayoutContent,
  NSpace,
  NModal,
  NRadioGroup,
  NRadio
} from 'naive-ui'

const components = [
  NButton,
  NForm,
  NFormItem,
  NInput,
  NCard,
  NIcon,
  NNotificationProvider,
  NConfigProvider,
  NMenu,
  NDatePicker,
  NDataTable,
  NPagination,
  NDialogProvider,
  NMessageProvider,
  NSpin,
  NSwitch,
  NLayoutSider,
  NLayout,
  NSpace,
  NLayoutHeader,
  NLayoutContent,
  NModal,
  NRadioGroup,
  NRadio
]
export default (app: App) => {
  app.use(
    create({
      components
    })
  )
}
